
<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card>
      <b-form>
        <b-row>
          <!--<b-col v-if="userPermission" sm="3">
            <b-form-group>
              <label for="from-date">Select Cinema Name</label>
              <b-form-select
                required
                v-model="cinemasObject.selectedCinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaID"
                :options="cinemasObject.cinemaList"
                @change.native="onChangeCinemaDropdwon($event.target.value)"
              >
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Cinema --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>-->
          <!-- <b-col v-if="userPermission" sm="3">
                          <b-form-group>
                            <label for="from-date">Select Movie Name</label>                            
                            <b-form-select required v-model="cinemasObject.selectedMovie" id="cinemas"
                                :plain="true"
                                :options=cinemasObject.moviesList
                                >
                            </b-form-select>
                        </b-form-group>
          </b-col>-->
        </b-row>
        <b-row>
          <b-col sm="3">
            <b-form-group>
              <label for="from-date">From Date :</label>
              <b-form-input type="date" v-model="FromDate"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="to-date">To Date :</label>
              <b-form-input type="date" v-model="ToDate"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <label for="cinema-name">Select Cinema Name</label>
              <b-form-select
                required
                v-model="cinemasObject.selectedCinema"
                id="cinemas"
                :plain="true"
                text-field="CinemaName"
                value-field="CinemaID"
                :options="cinemasObject.cinemaList"
                @change.native="onChangeCinemaDropdwon($event.target.value)"
              >
                <template slot="first">
                  <option :value="'0'" disabled>-- Select Cinema --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <b-button
                @click.prevent.stop="submit"
                class="mt-4"
                type="submit"
                size="sm"
                variant="primary"
              >
                <i class="fa fa-dot-circle-o"></i> Get Reports
              </b-button>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <b-button
                @click.prevent.stop="consolidated"
                class="mt-4"
                type="submit"
                size="sm"
                variant="primary"
              >
                <i class="fa fa-dot-circle-o"></i> Consolidated
              </b-button>
            </b-form-group>
          </b-col>
          <b-col sm="3">
            <b-form-group>
              <b-button
                @click.prevent.stop="consolidatedReportDownload"
                class="mt-4"
                type="submit"
                size="sm"
                variant="primary"
              >
                <i class="fa fa-dot-circle-o"></i> Download Concilated Report
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-card-header>
        <b-row>
          <b-col>
            <i class="icon-menu mr-1"></i>Reconcilation Report
          </b-col>
          <b-col class="col d-flex justify-content-end">
            <!--<b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadPDF('PDF')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as PDF
            </b-button>-->
            &nbsp;&nbsp;
            <b-button
              v-if="data.length > 0"
              :disabled="data.length > 0 ? false: true"
              v-on:click="onDownloadExcel('XLSX')"
              type="submit"
              size="sm"
              variant="primary"
            >
              <i class="fa fa-dot-circle-o"></i>Download as XLSX
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <div>
          <v-client-table
            :columns="columns"
            :data="data"
            :options="options"
            :theme="theme"
            id="dataTable"
          >
            <div slot="Action" slot-scope="props">
              <b-button
                :disabled="props.row.TransferID.length > 0"
                @click="submitTransferId(props.index, props.row.ID, props.row)"
                type="submit"
                variant="success"
                block
              >Submit</b-button>
            </div>
            <div slot="PaymentReferenceNumber" slot-scope="props">
              <b-form-group>
                <!-- <label for="to-date">text</label> -->
                <b-form-input
                  :disabled="props.row['PaymentReferenceNumber'] !== undefined && props.row['PaymentReferenceNumber'] !== ''"
                  type="text"
                  :value="props.row.PaymentReferenceNumber"
                  @blur="onTransferInputHandler($event,props.index,'PaymentReferenceNumber', props.row.ID)"
                ></b-form-input>
              </b-form-group>
            </div>
            <div slot="AmountReceived" slot-scope="props">
              <b-form-group>
                <!-- <label for="to-date">text</label> -->
                <b-form-input
                  :disabled="props.row['AmountReceived'] !== undefined && props.row['AmountReceived'] !== ''"
                  type="text"
                  :value="props.row.AmountReceived"
                  @blur="onTransferInputHandler($event,props.index,'AmountReceived', props.row.ID)"
                ></b-form-input>
              </b-form-group>
            </div>
            <div slot="DateOfReceipt" slot-scope="props">
              <b-form-group>
                <!-- <label for="to-date">text</label> -->
                <b-form-input
                  :disabled="props.row['DateOfReceipt'] !== undefined && props.row['DateOfReceipt'] !== ''"
                  type="date"
                  :value="props.row.DateOfReceipt"
                  @blur="onTransferInputHandler($event,props.index,'DateOfReceipt', props.row.ID)"
                ></b-form-input>
              </b-form-group>
            </div>
            <div slot="ActualPaymentDate" slot-scope="props">
              <b-form-group>
                <!-- <label for="to-date">text</label> -->
                <b-form-input
                  :disabled="props.row['ActualPaymentDate'] !== undefined && props.row['ActualPaymentDate']  !== ''"
                  type="date"
                  :value="props.row.ActualPaymentDate"
                  @blur="onTransferInputHandler($event,props.index,'ActualPaymentDate', props.row.ID)"
                ></b-form-input>
              </b-form-group>
            </div>
          </v-client-table>
        </div>
        <div class="mt-2">
          <v-client-table
            :columns="columns1"
            :data="data1"
            :options="options1"
            :theme="theme1"
            id="dataTable"
          ></v-client-table>
        </div>
        <div class="mt-2 d-none">
          <v-client-table
            :columns="columns2"
            :data="data2"
            :options="options2"
            :theme="theme2"
            id="dataTable"
          ></v-client-table>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import MasterService from "@/services/MasterService";
import ReportService from "@/services/ReportService";
import CinemaService from "@/services/CinemaService";
import MovieService from "@/services/MovieService";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import { dashboardName,WANASHAdashboardName } from "@/helper/constant";
import XLSX from "xlsx";
import { pdfFillColor } from "@/helper/constant";
import _ from "lodash";

export default {
  name: "TransactionDateReport",
  components: {
    cSwitch
  },
  data: function() {
    return {
      userPermission: true,
      cinemasObject: {
        cinemaList: [],
        selectedCinema: "0",
        moviesList: [],
        selectedMovie: "0"
      },
      FromDate: "",
      ToDate: "",
      Today: "",
      report: "",
      consolidatedValue: false,
      columns:
        localStorage.getItem("UserRole") == "Cinema"
          ? [
              //   "SrNo",
              "TransactionDate",
              "PaymentDate",
              "PayCycle",
              "SettlementAmount",
              "PayableToCinema",
              "TransferAmount",
              "PGAmount",
              "TotalAmount",
              "TicketPrice",
              "IHC",
              "NoofTickets",
              "PaymentReferenceNumber",
              // "Send",
              "Action"
            ]
          : [
              //   "SrNo",
              "TransactionDate",
              "PaymentDate",
              "PayCycle",
              "Theatre",
              "SettlementAmount",
              "SettlementBasedTwelve",
              "SettlementAmountConsideringFNB",
              "TotalFnBshare",
              "PayableToCinema",
              "TransferAmount",
              "PGAmount",
              "TotalAmount",
              "TicketPrice",
              "IHC",
              "NoofTickets",
              "PGChargesMT",
              "Difference",
              "PaymentDifference",
              "AmountReceived",
              "DateOfReceipt",
              "ActualPaymentDate",
              "PaymentReferenceNumber",
              // "Send",
              "Action"
            ],
      data: [],
      options: {
        headings: {
          //   SrNo: "Sr. No.",
          TransactionDate: "Transaction Date",
          PaymentDate: "Payment Date",
          PayCycle: "Pay Cycle",
          Theatre: "Cinema Name",
          SettlementAmountConsideringFNB: "Settlement Amount Considering F&B",
          SettlementBasedTwelve: "Settlement Amount Based On 12 To 12",
          SettlementAmount: "Settlement Amount",
          TotalFnBshare: "Total F&B Share",
          PayableToCinema: "IHC Share to Cinema",
          TransferID: "Transfer ID",
          TransferAmount: "Transfer Amount",
          PGAmount: "PG Amount",
          TotalAmount: "Total Amount",
          TicketPrice: "Ticket Price",
          IHC: "Internet Handling Chargers",
          NoofTickets: "Number of Tickets",
          PGChargesMT: "Settlement as per PG",
          Difference: "Difference",
          PaymentDifference: "Payment Difference",
          AmountReceived: "Amount Received",
          DateOfReceipt: "Date Of Receipt",
          ActualPaymentDate: "Actual Payment Date",
          PaymentReferenceNumber: "Remark",
          Send: "Send"
        },
        sortable: [
          "TransactionDate",
          "PaymentDate",
          "PayCycle",
          "SettlementAmount",
          "PayableToCinema",
          "TransferAmount",
          "PGAmount",
          "TotalAmount",
          "TicketPrice",
          "IHC",
          "NoofTickets"
        ],
        filterable: [
          "TransactionDate",
          "PaymentDate",
          "PayCycle",
          "SettlementAmount",
          "PayableToCinema",
          "TransferAmount",
          "PGAmount",
          "TotalAmount",
          "TicketPrice",
          "IHC",
          "NoofTickets"
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      downloadOptions: [
        {
          text: "Select type",
          value: ""
        },
        {
          text: "xls",
          value: "xls"
        },
        {
          text: "pdf",
          value: "pdf"
        }
      ],
      downloadType: "",
      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      columns1: [
        "TotalDifference",
        "TotalPGChargesMT",
        "TotalSettlement",
        "TotalSettlementWithFnB",
        "TotalPaymentDifference"
      ],
      data1: [],
      options1: {
        headings: {
          TotalDifference: "Total Difference",
          TotalPGChargesMT: "Total PG Charges",
          TotalSettlement: "Total Settlement",
          TotalSettlementWithFnB: "Total Settlement With FnB",
          TotalPaymentDifference: "Total Payment Difference"
        }
      },
      columns2: ["FailedNumberOfTickets", "FailedPrice"],
      data2: [],
      options2: {
        headings: {
          FailedNumberOfTickets: "No of Tickets",
          FailedPrice: "Failed Total Ticket Price + Convenience Fee"
        }
      }
    };
  },
  beforeMount() {
    if (
      localStorage.getItem("UserRole") &&
      localStorage.getItem("UserRole") == "Cinema"
    ) {
      this.userPermission = false;
    } else {
      this.getCinemasDDL(0);
      this.getMoviesAccordingCinema(null);
    }
  },
  mounted() {
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    }
    this.Today = formatDate(new Date());
    this.submit();
  },
  methods: {
    onDownloadExcel: function(type) {
      if (this.data.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.data);
        var dataTwo = XLSX.utils.json_to_sheet(this.data1);
        var dataThree = XLSX.utils.json_to_sheet(this.data2);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "TRANSACTIONS"); // sheetAName is name of Worksheet
        XLSX.utils.book_append_sheet(wb, dataTwo, "Success");
        XLSX.utils.book_append_sheet(wb, dataThree, "Failed");
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_RECONCILATION_REPORT_${new Date().toDateString()}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onDownloadPDF: function(type) {
      if (this.data.length > 0) {
        let mainArray = [];
        let arrayTwo = [];
        let arrayThree = [];
        let HeadTwo = ["SuccessNumberOfTickets", "SuccessPrice"];
        let HeadThree = ["FailedNumberOfTickets", "FailedPrice"];
        this.data.map(x => {
          let tempArray = [];
          this.columns.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          mainArray.push(tempArray);
        });
        this.data1.map(x => {
          let tempArray = [];
          HeadTwo.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          arrayTwo.push(tempArray);
        });
        this.data2.map(x => {
          let tempArray = [];
          HeadThree.map(y => {
            if (x.hasOwnProperty(y)) {
              tempArray.push(x[y]);
            }
          });
          arrayThree.push(tempArray);
        });
        var imgData = BASE64IMAGE;

        var doc = new jsPDF("l", "px", [1000, 600]);

        doc.addImage(imgData, "JPEG", 280, 0, 150, 100);
        doc.text(260, 100, `${WANASHAdashboardName.toUpperCase()} TRANSACTION REPORT`);
        doc.setFontSize(17);

        doc.autoTable({
          head: [this.columns],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: mainArray,
          margin: { top: 120 },
          headStyles: {
            // fillColor: [210, 29, 29]
            fillColor: pdfFillColor // [47, 23, 73]
            // fillColor: [0, 14, 54]
          }
        });
        doc.autoTable({
          head: [HeadTwo],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: arrayTwo,
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.autoTable({
          head: [HeadThree],
          theme: "grid", // 'striped'|'grid'|'plain'
          body: arrayThree,
          headStyles: {
            fillColor: pdfFillColor
          }
        });
        doc.save("RECONCILATION_REPORT_" + new Date().toDateString() + ".pdf");
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    onChangeCinemaDropdwon: function(cinema) {
      this.cinemasObject.selectedCinema = cinema;
      //this.getMoviesAccordingCinema(cinema);
    },
    getCinemasDDL: function(OrgID) {
      // let res = await CinemaService.getCinemasDDL(id);
      let payload = {
        OrgID
      };
      this.cinemasObject.cinemaList = [];
      CinemaService.getCinemasDDL(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemasObject.cinemaList = data.CinemaList;
            console.log(this.cinemasObject.CinemaList);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on AddCinema-OnSubmit Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    async getMoviesAccordingCinema(id) {
      let payload = {};
      if (id) {
        payload["CinemaID"] = id;
      }
      // let res = await MovieService.getMoviesAccordingCinema(payload);
      // this.cinemasObject.moviesList.push({
      //   text: "Select Movie",
      //   value: "0"
      // });
      // // console.log(">>>>>>>>>>>>>>>>", res.data);
      // res.data.map(o => {
      //   this.cinemasObject.moviesList.push({
      //     text: o.Title,
      //     value: o.MovieID
      //   });
      // });
    },
    onTransferInputHandler: function(event, index, name, ID) {
      let _index = _.findIndex(this.data, { ID });
      this.data[_index][name] = event.target.value;
    },

    submitTransferId: function(index, ID, row) {
      const {
        PaymentReferenceNumber,
        AmountReceived,
        DateOfReceipt,
        ActualPaymentDate
      } = row;
      console.log(
        "PaymentReferenceNumber,AmountReceived, DateOfReceipt,ActualPaymentDate :",
        PaymentReferenceNumber,
        AmountReceived,
        DateOfReceipt,
        ActualPaymentDate
      );
      // if(PaymentReferenceNumber === undefined && PaymentReferenceNumber.length == 0){
      //   return show
      // }else if()
      let _index = _.findIndex(this.data, { ID });
      let payload = {
        ID,
        TransferID: PaymentReferenceNumber || "",
        ActualPaymentDate: ActualPaymentDate || "",
        AmountReceived: AmountReceived || "",
        DateOfReceipt: DateOfReceipt || ""
      };
      console.log("payload :", payload);
      ReportService.addPaymentReference(payload)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
        })
        .catch(error => {
          console.log("Catch on AddCinema-OnSubmit Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    consolidated: function() {
      this.consolidatedValue = true;
      this.submit();
    },

    consolidatedReportDownload: function() {
      let ConsolidatedData = [];
      let payload = {
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        Consolidate: this.consolidatedValue
      };
      let currentCinemaId = "";
      if (this.cinemasObject.selectedCinema !== "0") {
        currentCinemaId = this.cinemasObject.selectedCinema;
        payload["CinemaID"] = this.cinemasObject.selectedCinema;
        let OrganizationID = this.cinemasObject.cinemaList.filter(cobj => {
          if (cobj.CinemaID == currentCinemaId) {
            return cobj.OrganizationID;
          }
        })[0].OrganizationID;
        console.log("OrganizationID :", OrganizationID);
        payload["OrgID"] = OrganizationID;
      }
      // let res = await ReportService.getTransactionDateReport(payload);
      ReportService.getCinemaPaymentListExcel(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            ConsolidatedData = data.Data;
            if (ConsolidatedData.length > 0) {
              var dataOne = XLSX.utils.json_to_sheet(ConsolidatedData);
              var wb = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, dataOne, "RECONCILED"); // sheetAName is name of Worksheet
              XLSX.writeFile(
                wb,
                `${WANASHAdashboardName.toUpperCase()}_RECONCILED_REPORT_${new Date().toDateString()}.xlsx`
              );
            } else {
              this.showMessageSnackbar("No data available");
            }
            console.log(ConsolidatedData);
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Saif
    submit: function() {
      this.data = [];
      this.data1 = [];
      let payload = {
        FromDate: this.FromDate,
        ToDate: this.ToDate,
        Consolidate: this.consolidatedValue
        // CinemaID: this.cinemasObject.selectedCinema
      };
      let currentCinemaId = "";
      if (this.cinemasObject.selectedCinema !== "0") {
        currentCinemaId = this.cinemasObject.selectedCinema;
        payload["CinemaID"] = this.cinemasObject.selectedCinema;
        let OrganizationID = this.cinemasObject.cinemaList.filter(cobj => {
          if (cobj.CinemaID == currentCinemaId) {
            return cobj.OrganizationID;
          }
        })[0].OrganizationID;
        console.log("OrganizationID :", OrganizationID);
      }
      if (this.cinemasObject.selectedMovie !== "0") {
        payload["MovieID"] = this.cinemasObject.selectedMovie;
      }
      // let res = await ReportService.getTransactionDateReport(payload);
      ReportService.getCinemaPaymentList(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            console.log("Data", data.Data);
            this.data = data.Data;
            //this.data1 = [];
            this.data1.push({
              TotalDifference: data.TotalDifference,
              TotalPGChargesMT: data.TotalPGChargesMT,
              TotalSettlement: data.TotalSettlement,
              TotalSettlementWithFnB: data.TotalSettlementWithFnB,
              TotalPaymentDifference: data.TotalPaymentDifference
            });
            this.data2 = [];
            console.log(this.data1);
            //this.consolidatedValue = false;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
</style>
